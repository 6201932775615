<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <b-form-group class="mr-1 mb-md-0">
            <div class="d-flex">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                class="flex-grow-1 mx-1"
                @click="triggerFileInput"
              >
                <i class="fas fa-file-import" /> Import เบอร์โทร
              </b-button>

              <input
                ref="fileInput"
                type="file"
                style="display: none;"
                @change="handleFileUpload"
              >

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.exportDepositModal
                variant="outline-danger"
                class="flex-grow-1 mx-1"
              >
                <i class="fas fa-file-export" /> Export เบอร์โทร
              </b-button>
              <b-modal
                id="exportDepositModal"
                title="ระบุรหัสผ่าน"
                @ok="checkExportPassword"
              >
                <b-form-input v-model="exportPassword" />
              </b-modal>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-warning"
                class="flex-grow-1 mx-1"
                @click="CheckTel()"
              >
                <i class="fas fa-search" /> ตรวจสอบเบอร์
              </b-button>
            </div>
          </b-form-group>

          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <a
              :href="`http://${data.item.ip}`"
              target="_blank"
            >{{ data.item.ip }}</a>

          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="editData(data.item)"
            />
            <b-tooltip
              title="Preview Invoice"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>
        <template #cell(regis)="data">
          <p> {{ data.item.register == 0 ? 'ยังไม่ได้สมัคร' : data.item.register == 1 ? 'สมัครแล้ว' : 'ยังไม่ได้ตรวจสอบ' }}</p>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @input="getSMS()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getSMS()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-1"
        title="เติมเครดิต"
        ok-title="เติมเครดิต"
        @ok="submit"
      >
        <b-form-group>
          <v-select
            v-model="member"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="username"
            :options="optionsMember"
          />
        </b-form-group>
        <b-form-group
          label="จำนวนเงินที่ฝาก"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
            <!-- <feather-icon icon="CheckSquareIcon" /> -->
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              v-model="amount"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import {
  BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, VBToggle, BOverlay, BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import * as XLSX from 'xlsx'

export default {
  components: {
    vSelect,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      show: false,
      depositdata: [],
      smsData: '',
      member: '',
      amount: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'tel', label: 'เบอร์โทร' },
        { key: 'count', label: 'จำนวนการส่ง' },
        { key: 'regis', label: 'สถานะ' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },

      ],
      fields_bk: [
        { key: 'index', label: 'no.' },
        { key: 'sender', label: 'sender' },
        { key: 'message', label: 'message' },
        { key: 'time', label: 'เวลา' },
        { key: 'amount', label: 'จำนวนเงิน' },
        { key: 'status', label: 'status' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },

      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    // this.interval = setInterval(() => {
    //   this.getSMS()
    // }, 30000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    this.getSMS()
  },
  methods: {
    async checkExportPassword() {
      this.show = true
      const obj = {
        password: this.exportPassword,
      }
      this.$http
        .post('/export/checkpass', obj)
        .then(response => {
          if (response.data.status === true) {
            this.exportData()
            this.show = false
          } else {
            this.show = false
            this.$swal({
              icon: 'warning',
              title: '<h3 style="color: #141414">แจ้งเตือน</h3>',
              text: 'รหัสผ่านไม่ถูกต้อง',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }
        })
        .catch(error => console.log(error))
    },
    async exportData() {
      try {
        const { data } = await this.$http.get('/thaibulk/ExportExcel', {
          responseType: 'blob',
          params: {

          },
        })

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(
          new Blob([data]),
        )
        // link.setAttribute(`download', 'Export Deposit Date ${this.dateStart} - ${this.dateEnd}.xlsx`)
        link.setAttribute('download', 'ExportTel.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        console.log(error)
      }
    },
    CheckTel() {
      this.show = true
      this.$http
        .get(`thaibulk/script/${this.UserData.agent_id}`)
        .then(response => {
          this.Success('กำลังตรวจสอบเบอร์โทร')
          console.log(response)
          this.getSMS()
        })
        .catch(error => console.log(error))
    },
    getSMS() {
      // this.items = []
      this.show = true
      // let index = 0
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_type: this.search_type,
        search_val: this.search_val,
        search_from: this.search_from,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .post('/thaibulk/index', params)
        .then(response => {
          // console.log(response.data)
          this.show = false
          // this.depositdata = response.data.data
          // this.depositdata.forEach(items => {
          //   this.getAdmin(items.addby, index)
          //   index += 1
          // })
          this.onFiltered(response.data)

          this.show = false
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      // console.log(filteredItems.data)
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    triggerFileInput() {
      this.$refs.fileInput.click()
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = e => {
          const data = new Uint8Array(e.target.result)
          const workbook = XLSX.read(data, { type: 'array' })
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]]
          const jsonData = XLSX.utils.sheet_to_json(firstSheet)
          // Extract the phone numbers
          const phoneNumbers = jsonData.flatMap(item => Object.values(item))
          // console.log(phoneNumbers) // Process your data here
          this.submit(phoneNumbers)
        }
        reader.readAsArrayBuffer(file)
      }
    },
    submit(data) {
      const obj = {
        data,
      }
      this.$http
        .post('/thaibulk/import/excel', obj)
        .then(response => {
          console.log(response)
          this.Success('นำข้อมูลเข้าสำเร็จ')
        })
        .catch(error => console.log(error))
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
